$(function() {
  var MODAL_TRIGGER_SELECTOR = '.js-remote-modal-trigger';
  var $body = $('body');
  addEvents();

  function addEvents() {
    $(document)
      .on('ajax:before',   MODAL_TRIGGER_SELECTOR, disableClick)
      .on('ajax:complete', MODAL_TRIGGER_SELECTOR, enableClick)
      .on('ajax:success',  MODAL_TRIGGER_SELECTOR, settingAndShowModal);
  }

  function settingAndShowModal(event) {
    $('body').find('.modal').modal('hide'); // Modalは一つまで。

    var modal = event.detail[2].response;
    var $modal = $(modal);
    $body.append($modal);

    $modal.modal('show');

    $modal.on('hidden.bs.modal', function() {
      $modal.remove();
    });
  }

  function disableClick(event) {
    $target = $(event.currentTarget);
    $target.prop('disabled', true);
  }

  function enableClick(event) {
    $target = $(event.currentTarget);
    $target.prop('disabled', false);
  }
});