<template>
<div :class=classes.selector>
  <button v-if=isMobile class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">{{selectedArea}}<span class="caret"></span></button>
  <ul :class=classes.ul>
    <li v-for="area in areas" :key="area" @click=areaClicked(area) :class="{active: area==selectedArea}"> {{area}} </li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    areas: Array,
    selectedArea: String
  },
  data() {
    return {
      isMobile: false,
      classes: {
        selector: 'c-tab u-mb10',
        ul: ''
     }
    }
  },
  mounted() {
    this.isMobile = this.$parent.isMobile
    if (this.isMobile){
      this.classes.selector = 'dropdown'
      this.classes.ul = 'dropdown-menu'
    }
  },
  methods: {
    areaClicked(e){
      this.$emit('area-clicked', e)
    }
  }
}
</script>
