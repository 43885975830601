import Vue from 'vue/dist/vue.esm';
import LocationModal from './components/db_companies_search/location_modal';
import {state} from './stores/db_companies_search/store'



// const dbCompaniesCountData = () => {
//   const el = document.getElementById('new_db_companies_search');
//   return el ? JSON.parse(el.dataset.dbCompaniesCount) : null;
// };

export default {
  data: {
    activeComponent: state.activeComponent,
    searchFormState: null,
    // dbCompaniesCount: dbCompaniesCountData(),
    dbCompaniesSearch: state.dbCompaniesSearch,
    isAccordionActive: [],
    isPrefectureModalShow: false,
    isConstrustionIndustryModalShow: false,
    isConditionModalShow: false,
  },
  created() {
    this.parseHashForSeachForm();
    window.addEventListener('hashchange', this.parseHashForSeachForm);
  },
  computed: {
    locationText(){
      return state.locationsArray.join(', ')
    }
    // prefectureJobsCount() {
    //   let sum = 0;
    //   if (this.dbCompaniesSearch.prefecture_names.length === 0) {
    //     Object.keys(this.dbCompaniesCount).forEach((key) => {
    //       sum += this.dbCompaniesCount[key];
    //     });
    //     return sum;
    //   }


    //   this.dbCompaniesSearch.prefecture_names.forEach((elem) => {
    //     let count = 0;

    //     if (this.dbCompaniesCount[elem]) {
    //       count = this.dbCompaniesCount[elem];
    //     }
    //     sum += count;
    //   });

    //   return sum;
    // },
  },
  components:{
    LocationModal
  },
  methods: {
    parseHashForSeachForm() {
      const hashKey = window.location.hash.replace('#', '');
      switch (hashKey) {
      case 'search_by_license':
      case 'search_by_prefecture':
      case 'search_by_other':
        this.searchFormState = hashKey;
        break;
      default:
        this.searchFormState = null;
      }
    },
    setHash(hashKey) {
      window.location.hash = hashKey;
    },
    reselectLocation() {
      if (!this.dbCompaniesSearch.prefecture_name) {
        this.dbCompaniesSearch.area_name = null;
      }
      this.dbCompaniesSearch.prefecture_name = null;
      this.dbCompaniesSearch.city_names = [];
    },
    toggle(index) {
      this.isAccordionActive[index] = this.isAccordionActive[index];
      this.isAccordionActive.splice(index, 1, !this.isAccordionActive[index]);
    },
    openLocationModal(){
      this.$refs.lm1.openModal()
    },
    openPrefectureModal() {
      this.isPrefectureModalShow = true;
      // $('body').css('overflow', 'hidden');
    },
    openConstructionIndustryModal(event) {
      event.preventDefault();
      this.isConstrustionIndustryModalShow = true;
      // $('body').css('overflow', 'hidden');
    },
    closePrefectureModal() {
      this.isPrefectureModalShow = false;
      // $('body').css('overflow', 'auto');
    },
    closeConstructionIndustryModal() {
      this.isConstrustionIndustryModalShow = false;
      // $('body').css('overflow', 'auto');
    },
    openConditionModal() {
      this.isConditionModalShow = true;
    },
    closeConditionModal() {
      this.isConditionModalShow = false;
    },
    selectAllPrefecture(areaName) {
      const areaNameChecked = document.querySelector(`input[value="${areaName}"]`).checked;
      if (areaNameChecked) {
        document.querySelectorAll(`input[data-area-name="${areaName}"]`).forEach((elem) => {
          this.dbCompaniesSearch.prefecture_names.push(elem.value);
        });
      } else {
        document.querySelectorAll(`input[data-area-name="${areaName}"]`).forEach((elem) => {
          this.dbCompaniesSearch.prefecture_names.pop(elem.value);
        });
      }

      this.dbCompaniesSearch.prefecture_names = this.dbCompaniesSearch.prefecture_names.filter((x, i, self) => self.indexOf(x) === i);
    },
    deselectArea(prefectureName) {
      const prefectureNameInput = document.querySelector(`input[value="${prefectureName}"]`);
      if (!prefectureNameInput.checked) {
        document.querySelector(`input[value="${prefectureNameInput.dataset.areaName}"]`).checked = false;
      }
    },

    selectAllLicenses(majorLicenseName) {
      Vue.nextTick(() => {
        const majorLicenseNameChecked = this.dbCompaniesSearch.license_names.includes(majorLicenseName);

        if (majorLicenseNameChecked) {
          document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
            .forEach((elem) => {
              this.dbCompaniesSearch.license_names.push(elem.value);
            });
        } else {
          document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
            .forEach((elem) => {
              const index = this.dbCompaniesSearch.license_names.indexOf(elem.value);
              this.dbCompaniesSearch.license_names.splice(index, 1);
            });
        }

        this.dbCompaniesSearch.license_names = this.dbCompaniesSearch.license_names.filter((x, i, self) => self.indexOf(x) === i);
      });
    },
    selectLicense(licenseName) {
      Vue.nextTick(() => {
        const licenseNameInput = document.querySelector(`input[value="${licenseName}"]`);
        this.checkAllLicense(licenseNameInput.dataset.majorLicenseName);
      });
    },
    checkAllLicense(majorLicenseName) {
      const array = [];

      document.querySelectorAll(`input[data-major-license-name="${majorLicenseName}"]`)
        .forEach((elem) => {
          array.push(this.dbCompaniesSearch.license_names.includes(elem.value));
        });

      if (array.every(val => val === true)) {
        this.dbCompaniesSearch.license_names.push(majorLicenseName);
      } else if (this.dbCompaniesSearch.license_names.includes(majorLicenseName)) {
        const index = this.dbCompaniesSearch.license_names.indexOf(majorLicenseName);
        this.dbCompaniesSearch.license_names.splice(index, 1);
      }
    },
  },
};
