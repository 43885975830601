import Vue from 'vue/dist/vue.esm';

const dbCompaniesSearchData = () => {
  const el = document.getElementById('new_db_companies_search');
  return el ? JSON.parse(el.dataset.dbCompaniesSearch) : null;
};
export const state = Vue.observable({
    dbCompaniesSearch: dbCompaniesSearchData(),
    activeComponent: '',
    locationsArray: [],
  })
export const mutations = {
    setCities(state, cities){
      state.dbCompaniesSearch.city_names = cities
    },
    setPrefectures(state, prefectures){
      state.dbCompaniesSearch.prefecture_names = prefectures
    },
    setactiveComponent(state, el){
      state.activeComponent = el
    },
    setLocationsArray(state, arr){
      state.locationsArray = arr
    }
  }
