import Vue from 'vue/dist/vue.esm';
import DbCompaniesSearchMixin from './vue/db_companies_search_mixin';
import AutocompleteMixin from './vue/autocomplete_mixin';

document.addEventListener('DOMContentLoaded', () => {
  const vueParams = {
    el: '#body_wrapper',
  };

  // 配列にしないとエラーで動作しない
  vueParams.mixins = [DbCompaniesSearchMixin];
  vueParams.mixins.push(AutocompleteMixin)
  new Vue(vueParams);

});
