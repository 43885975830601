<template>
<div>
  <!-- <div v-show=isLoading class='c-loading__spinner centered'></div> -->
  <table v-show=!isLoading>
    <tr><th><label class="c-checkbox">
      <input type=checkbox :value=selectedPrefecture v-model=prefecture_names>
      <span class="c-checkbox__text">{{selectedPrefecture}}</span>
    </label></th></tr>
    <tr>
    <td v-for="city in cities" :key=city>
      <label class="c-checkbox">
        <input type=checkbox :value=city  v-model=city_names>
        <span class="c-checkbox__text">{{city}}</span>
      </label>
    </td>
    </tr>
  </table>
</div>
</template>

<script>
import {state, mutations} from '../../stores/db_companies_search/store'

export default {
  props: {
    cities: Array,
    selectedPrefecture: String,
    isLoading: Boolean,
  },
  computed:{
    currentPrefSelectedCities(){
      return this.city_names.filter(x => this.cities.indexOf(x) != -1)
    },
    isAllChecked(){
      return this.cities.length == this.currentPrefSelectedCities.length
    },
    city_names: {
      get: function(){
        return state.dbCompaniesSearch.city_names
      },
      set: function(value){
        mutations.setCities(state, value)

        if (this.isAllChecked){
          this.prefecture_names.push(this.selectedPrefecture)
        }
        else {
          let i = this.prefecture_names.indexOf(this.selectedPrefecture)
          if (i > -1){this.prefecture_names.splice(i,1)}
        }
      }
    },
    prefecture_names:{
      get: function(){
        return state.dbCompaniesSearch.prefecture_names
      },
      set: function(value){
        mutations.setPrefectures(state, value)

        if (this.prefecture_names.includes(this.selectedPrefecture)) {
          let new_cities = this.cities.filter(x => this.city_names.indexOf(x) === -1)
          new_cities.forEach((x) => {this.city_names.push(x)})
        }
        else {
          this.cities.forEach((x) => {
            let i = this.city_names.indexOf(x)
            if (i > -1){this.city_names.splice(i,1)}
          })
        }
      } 
    }
  },
}
</script>
