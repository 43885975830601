import SuggestionContainer from './components/suggestion_container'
const axios = require('axios');

export default {
  data() {
    const vm = this
    return {
      autocomplete: {
        freeword: '',
        handlers: {
          keydown: vm.navigateSuggestions,
          input: vm.fetchSuggestions,
          blur: vm.deactiveSuggestions,
          // focus: vm.activateSuggestions,
        }
      },
    }
  },
  computed:{
    freewords(){
      let arr = this.autocomplete.freeword.replace('　',' ')
      return arr.split(' ')
    },
    current_word(){
      return this.freewords[this.freewords.length - 1]
    },
  },
  components:{
    SuggestionContainer
  },
  methods: {
    fetchSuggestions(e){
      const vm = this
      vm.autocomplete.freeword = e.target.value
      let ref = e.target.id
      if (this.current_word.length > 0){
        axios
          .get('/autocomplete?free_word='+this.current_word)
          .then(response => {
            vm.$refs[ref].setSuggestions(response.data)
            vm.$refs[ref].setActive(true)
          })
          .catch(e => {
            console.log(e)
          })
      }
    },
    navigateSuggestions(e){
      const vm = this
      let sc = vm.$refs[e.target.id]
      // Down-arrow key pressed
      if (e.keyCode == 40){
        sc.focusInc()
        return
      }
      // Up-arrow key pressed
      if (e.keyCode == 38){
        sc.focusDec()
        return
      }
      // Enter key pressed
      if (e.keyCode == 13 && sc.currentFocus > -1 ){
        e.preventDefault()
        sc.selectSuggestion(e)
        return
      }

      sc.setFocus(-1)
    },
    activateSuggestions(e){
      let ref = e.target.id
      this.$refs[ref].setActive(true)
    },
    deactiveSuggestions(e){
      let ref = e.target.id
      this.$refs[ref].setActive(false)
    },
    updateInput(e){
      this.autocomplete.freeword = e
    }
  }
}