window.addEventListener('DOMContentLoaded', function () {
    $(function () {

        $('.sub-menu').on({
            'mouseenter': function () {
                $(this).addClass('is-active');
            },
            'mouseleave': function () {
                $(this).removeClass('is-active');
            }
        });
        $("#nav-toggle, #overlay").off().on('click', function (e) {
            var c = $('.p-users-container')
            if (c.hasClass('p-users-container__open')) {
                c.removeClass('p-users-container__open');
            } else {
                c.addClass('p-users-container__open');
            }
        });
    });
});